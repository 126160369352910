/* src/components/Header.css */

.header {
    background: url('/public/pic03.jpg') no-repeat center center;
    background-size: cover;
    color: white;
    text-align: center;
    padding: 100px 0;
}

.header h1 {
    margin: 0;
    padding: 0;
    font-size: 2.5em;
}

.header p {
    font-size: 1.2em;
}

