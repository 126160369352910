body {
    background-color: white;
    height: 100%
}

.col-6 {
    background-color: white;
    opacity: 0.95;
    margin-top: 0%;
    margin-bottom: 0%;
    height: 100%

}

.img-responsive {
    margin-top: 5rem;
    max-width: 70%;
    height: auto;

}

.row {
    height: 100vh;
    margin-right: 0;
    margin-left: 0;
}

#homeText {
    text-align: justify;
}

h1 {
    text-align: center;
}

h2 {
    text-align: center;
}

